<template>
	<div class="h5-container">
		<BACKH5 :title="$t('Module.OurTeam')" url="/h5" />
		<div class="main">
			<div id="box-wrapper">
				<template v-for="(item,type) in list.types">
					<div class="box">
						<div class="box-title">{{item}}</div>
						<div class="list">
							<div v-for="(person,index) in list.persons" :key="index" v-if="person.type == type" class="list-item" @click="toDetail(person.id)">
								<img :class="['img', { 'fade-up': person.show_hover }]" 
								:src="person.show_hover ? person.pic2 : person.pic1" 
								@mouseenter="changeShowHover(person.id)" 
								@mouseleave="changeShowHover(person.id)" 
								:style="{ height: imgHeight + 'px' }" />
								<div class="info">
									<div class="position">{{person.tags}}</div>
									<div class="name">{{person.name}}</div>
									<div class="desc">{{person.intro}}</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		components: {
		    BACKH5: () => import('@/components/BackH5.vue')
		},
        data() {
            return {
				list: {
					types: [],
					persons: []
				},
				imgHeight: 0
            }
        },
		created() {
		    this.getList()
		},
		mounted() {
            // 首次加载时,需要调用一次
            this.setSize()
            // 窗口大小发生改变时,调用一次
            window.onresize = () => {
                this.setSize()
            }
        },
        methods: {
			setSize() {
                // 获取Banner宽度
                let img_width = document.getElementById('box-wrapper').clientWidth
                // 获取Banner高度
                this.imgHeight = img_width * 1.5
            },
			
			getList() {
				this.$api.team_person({},300000).then(res => {
				    this.list = res.data
					this.list.persons = this.list.persons.map(item => {
						item.show_hover = false
						return item
					})
				})
			},
			
			changeShowHover(id) {
				this.list.persons = this.list.persons.map(item => {
					if(item.id == id) {
						item.show_hover = !item.show_hover
					}
					return item
				})
			},
			
			toDetail(id) {
				this.$router.push({
					path: '/h5/TeamDetail',
					query: {
						id
					}
				})
			}
        }
    }
</script>

<style lang="scss" scoped>
	.h5-container {
		background-color: #f6f6f6;
		
		.main {
			width: 100%;
			box-sizing: border-box;
			padding: 1.4rem 1.2rem;
			
			#box-wrapper {
				width: 100%;
			}
			
			.box {
				width: 100%;
				
				&:not(:last-child) {
					margin-bottom: 2.8rem;
				}
				
				.box-title {
					position: relative;
					width: 100%;
					margin-bottom: 2.2rem;
					font-size: 2.2rem;
					font-weight: bold;
					color: #000000;
					text-align: center;
					word-wrap: break-word;
					
					&::after {
						position: absolute;
						bottom: -.8rem;
						left: 50%;
						transform: translateX(-50%);
						content: '';
						width: 6rem;
						height: .3rem;
						border-radius: .3rem;
						background-color: #5466EF;
					}
				}
				
				.list {
					width: 100%;
					
					.list-item {
						position: relative;
						width: 100%;
						
						&:not(:last-child) {
							margin-bottom: 1.2rem;
						}
						
						@keyframes fadeUp {
							0% {
								opacity: 0;
							}
						
							100% {
								opacity: 1;
							}
						}
						
						.img {
							display: block;
							width: 100%;
							border-radius: .6rem;
						}
						
						.fade-up {
							animation: fadeUp .5s;
							animation-timing-function: linear;
							-webkit-animation: fadeUp .5s;
							-webkit-animation-timing-function: linear;
						}
						
						.info {
							position: absolute;
							bottom: 2rem;
							left: 50%;
							transform: translateX(-50%);
							width: calc(100% - 2.8rem);
							
							.position {
								width: 100%;
								margin-bottom: .4rem;
								font-size: 1.2rem;
								font-weight: bold;
								color: #FFFFFF;
								word-wrap: break-word;
							}
							
							.name {
								width: 100%;
								margin-bottom: .4rem;
								font-size: 2.2rem;
								font-weight: bold;
								color: #FFFFFF;
								word-wrap: break-word;
							}
							
							.desc {
								width: 100%;
								font-size: 1.4rem;
								font-weight: bold;
								color: #FFFFFF;
								word-wrap: break-word;
							}
						}
					}
				}
			}
		}
	}
</style>